import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuditCase, BaseForm, getEnumOptions } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { AuditCaseDataService } from '../../services/data/audit-case-data.service';

@Component({
  selector: 'app-audit-case-item-form',
  templateUrl: './audit-case-item-form.component.html',
  styleUrls: ['./audit-case-item-form.component.scss'],
})
export class AuditCaseItemFormComponent extends BaseForm<AuditCase> implements OnInit {
  controlClassification = getEnumOptions(AuditCase.ControlClassificationEnum);
  constructor(
    public viewModeService: ViewModeService,
    private requestService: AuditCaseDataService
  ) {
    super(viewModeService, 'AUDIT_CASE');
  }
  // plannedStartDate;
  // plannedEndDate;
  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      instruction: new FormControl(null),
      owner: new FormControl(null, Validators.required),
      respondent: new FormControl(null, Validators.required),
      control: new FormControl(null, Validators.required),
      auditQuestionnaire: new FormControl(null, Validators.required),
      evidenceQuestionnaire: new FormControl(null, Validators.required),
      auditor: new FormControl(null, Validators.required),
      auditee: new FormControl(null),
      // plannedStartDate: new FormControl(null, Validators.required),
      // plannedEndDate: new FormControl(null, Validators.required),
      // plannedDuration: new FormControl(null, Validators.required),
      // actualStartDate: new FormControl(null),
      // actualEndDate: new FormControl(null,),
      // actualDuration: new FormControl(null,),
      // actualTimeResources: new FormControl(null,),
      auditCaseTemplate: new FormControl(null),
    });
  }
  //   this.formGroup.controls.plannedStartDate.valueChanges.subscribe(x => {
  //     if (x && typeof x === 'string') {
  //       this.plannedStartDate = new Date(x);
  //     } else {
  //       this.plannedStartDate = x;
  //     }
  //     this.patchPlannedDuration();
  //   });

  //   this.formGroup.controls.plannedEndDate.valueChanges.subscribe(x => {
  //     if (x && typeof x === 'string') {
  //       this.plannedEndDate = new Date(x);
  //     } else {
  //       this.plannedEndDate = x;
  //     }
  //     this.patchPlannedDuration();
  //   });
  // }

  // patchPlannedDuration() {
  //   if (this.plannedStartDate && this.plannedEndDate) {
  //     var difference_ms = this.plannedEndDate.getTime() - this.plannedStartDate.getTime();

  //     var difference_days = Math.floor(difference_ms / (1000 * 60 * 60 * 24));
  //     this.formGroup.controls.plannedDuration.patchValue(difference_days);
  //   }
  // }
}
